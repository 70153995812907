.wwu-box {
    padding-top: 40px;
    height: 380px;
    clip-path: polygon(50% 0, 70% 0, 100% 0, 100% 100%, 75% 75%, 50% 82%, 25% 75%, 0 100%, 0 0, 35% 0);
    // background: var(--primary-color);
    background: -webkit-linear-gradient(to right, #5B86E5, #2C4773);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #5B86E5, #2C4773); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */

    a {
        font-size: 16px;
        font-weight: 500;
        color: white;
    }
    &__title {
        color: #BFD8FF;
        font-size: 200%;
        text-align: center;
        padding: 45px 0px 5px 0px; 
    }
    &__text {
        text-align: center;
        justify-content: center;
        
        &--color {
            font-size: 16px;
            font-weight: 700;
            color: var(--title-color);
        }
    }
    @include media-up(sm) {
        clip-path: polygon(50% 0, 70% 0, 100% 0, 100% 100%, 75% 75%, 50% 82%, 25% 75%, 0 100%, 0 0, 35% 0);

        // clip-path: polygon(50% 0, 70% 0, 100% 0, 100% 100%, 75% 50%, 50% 82%, 25% 50%, 0 100%, 0 0, 35% 0);

        &__title {
            font-size: 300%;
        }
    }

    @include media-up(lg) {
        &__text {
            display: flex;
            text-align: center;
            justify-content: center;
            color: var(--text-color)
        }
    }
}
.wwu-steps {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 40px;
    img{
        // display: none;
        height: 250px;
    }
    @include media-up(md){
        display: flex;
        flex-direction: row;   
    }

}

.step-circle-text{
    display: flex;
}
.wwu-circular {
    display: flex;
    // justify-content: flex-start;
    background-color: var(--secundary-color);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-right: 20px;

    h1 {
        margin-top: 0px !important;
    }

    &__number {
        display: flex;
        transition: transform .7s ease-in-out;
        &:hover {
            transform: rotate(360deg);
        }
        h1 {
            font-size: 300%;
            color: white;
            padding: 15px 27px;
        }
    }
    @include media-up(sm) {
        &__text {
            font-size: 200%;
        }
    }
}

.wwu-text-container{
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 200px;
    @include media-up(sm){
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        width: 350px;
    }
}

.wwu-mail-container{
    width: 350px;
    height: 110px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: hsl(221, 59%, 80%);
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 80px;
    border-radius: $borderRadius;
}

.wwu-mail-content{
    display: flex;
    justify-content: center;
    align-items: center;
    h5{
        font-size: 20px;
        margin-right: 15px;
        color: var(--text-color);
        margin-top: 10px;
    }
    img{
        height: 60px;
        margin-bottom: 20px;
    }
    transition: all 0.2s ease;
    &:hover{
        transform: scale(0.98,0.98);
    }
}

.wwu-help {
    text-align: center;
    justify-content: center;
    h1 {
        font-size: 16px;
    }
    &__wsp {
        color: rgb(8, 228, 116);
        padding-bottom: 50px;
    }
    @include media-up(lg) {
        display: flex;
    }
    @include media-up(sm) {
        h1 {
            font-size: 20px;
        }
    }
}