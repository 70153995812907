* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  text-decoration: none;
}

body {
  width: 100%;
  font-family: $Rubik;
  color: var(--text-color);
  line-height: normal;
  &::-webkit-scrollbar {
    width: 9px;
    background-color: hsl(210, 12%, 90%);
  }
  &::-webkit-scrollbar-thumb {
    background-color: hsl(210, 12%, 75%);
    border-radius: 10px;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $Nunito;
  color: var(--title-color);
}

#root {
  height: 100%;
}

