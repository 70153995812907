.custom-modal {
  position: fixed;
  z-index: 100;
  background: white;
  border: 1px solid #ccc;
  transition: 1.1s ease-out;
  box-shadow: -2rem 2rem 2rem rgba(0, 0, 0, 0.2);
  visibility: visible;

  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 75%;
  height: 150px;
  margin: auto;

  @include media-up(md){
    width: 500px;
  }
}

.custom-modal.off {
  opacity: 0;
  visibility: hidden;
  filter: blur(8px);
  transform: scale(0.33);
  box-shadow: 1rem 0 0 rgba(0, 0, 0, 0.2);
}

.custom-modal h2 {
  border-bottom: 1px solid #ccc;
  padding: 1rem;
  margin: 0;
}

.custom-modal .content {
  padding: 1rem;
}

.custom-modal .actions {
  border-top: 1px solid #ccc;
  background: #eee;
  padding: 0.5rem 1rem;
}

.custom-modal .actions button {
  border: 0;
  background: green;
  color: white;
  border-radius: 5px;
  padding: 0.5rem 1rem;
  font-size: 0.8rem;
  line-height: 1;
  margin: 1px;
}

.custom-modal-button-color {
  background-color: red !important;
}
