.support-box {
    min-height: 200px;
    background: -webkit-linear-gradient(to right, #5B86E5, #2C4773);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #5B86E5, #2C4773); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    

    a {
        color: white
    }

    &__title {
        color: #BFD8FF;
        font-size: 200%;
        text-align: center;
        padding: 20px 0px 5px 0px; 
    }

    &__text {
        text-align: center;
        justify-content: center;
        // font-size: 120%;
        font-weight: 500;
        &--color {
            color: var(--title-color);
        }
    }

    @include media-up(sm) {
        &__title {
            font-size: 300%;
            padding-top: 40px;
        }
    }

    @include media-up(xl) {
        &__text {
            font-size: 16px;
            text-align: center;
            justify-content: center;
        }
    }
}

.support-inputs {
    justify-content: center;
    text-align: center;
    padding: 40px 20px;
    margin: 0 auto;
    width: 40%;
    min-width: 350px;

    &__margin {
        margin-top: 40px !important; 
    }
}

.support-form-button {
    margin-top: 30px !important;
    background: var(--primary-color) !important;
    color: white !important;
}