.service-row{
    display: block;
    margin-top: -25px;
    // height: 235px;

    @include media-up(lg){
        // display: grid;
        // grid-template-columns: repeat(5, 1fr);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    a{
        color: var(--text-color);
    }
}

/*==================
    Render Card 
==================*/ 
.service-card{
    background-color: var(--card-color);
    margin-bottom: 60px;

    @include media-up(xxs){
        width: 70%;
        margin: ($spacing-lg+50) auto; 
    }

    @include media-up(md){
        width: 70%;
        margin: ($spacing-lg+50) auto; 
    }
    @include media-up(lg){
        width: 350px;
        margin: ($spacing-lg+50) 10px 0 10px ;
    }
}

.service-item{
    padding: 40px 36px 32px;
    transition: all 0.4s ease-out 0s;
    text-align: center;
    position: relative;
    &:hover{
        box-shadow: 0px 8px 8px 1px rgba(0, 0, 0, 0.2);
        transition: all 0.4s ease-out 0s;

        .service-item__icon{
            transition: all 1000ms ease;
            transform: rotateY(360deg);
            background-color: var(--secundary-color);
        }
    }

    &__icon{
        text-align: center;
        margin: auto;
        overflow: hidden;
        border-radius: 50%;
        position: relative;
        height: 100px;
        width: 100px;
        margin-top: -90px;
        margin-bottom: 12px;
    }

    &__img{
        margin-top: 0px;
        max-width: 100%;
    }

    &__title{
        font-size: 20px;
    }

    &__description{
        font-size: $description-size;
        
        // @include media-up(lg){
        //     // height: 260px;
        //     width: 350px;
        // }
    }
    @include media-up(lg){
        // height: 260px;
        width: 350px;
    }
}