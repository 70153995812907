.product-card-list {
  display: flex;
  justify-content: center;
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  min-height: calc(100% - 820px); 
}

.product-card {
  width: 360px;
  height: fit-content;
  margin: 10px;
  border: 2px solid #e9e9ea;
  border-radius: 24px;
  overflow: hidden;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  &__header {
    width: 95%;
    height: 100px;
    margin: 20px 20px;
    margin-bottom: 20px;
    h1 {
      margin: 20px 20px;
      font-size: 18px;
    }
    p {
      margin: 20px 20px;
      font-size: 14px;
    }
  }
  li {
    list-style: none;
  }
  @include fade(fadeInUp, ease, 1s, 1, forward);
}
.subservice-details-container {
  width: 100%;
  height: 100%;
  margin-top: 20px;
  background-color: #f4f4f5;
  border-top: 2px solid #e9e9ea;
  overflow: hidden;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  //   titulo y puntos
  h4 {
    margin-top: 10px;
    font-size: 16px;
  }
  p {
    margin-bottom: 0;
    font-size: 12px;
  }
}

.included-container {
  margin: 30px 20px 0 20px;
}

.not-included-container {
  margin: 20px 20px 30px 20px;
}

.extra-notes-container {
  margin: 20px 20px 30px 20px;
}


.button-service {
  background: lightgrey;
  border: 0 var(--secundary-color);
  border-radius: $borderRadius+5;
  color: #fff;
  width: 270px;
  height: 56px;
  margin-top: 15px;
  position: relative;
  &__text {
    font-size: 18px;
  }
  &--active{
    background: var(--secundary-color);
    &:hover {
      background-color: var(--secundary-color-hover);
    }
  }
}

.row-box {
  display: flex;
  flex-direction: row;
  img {
    margin-right: 5px;
    margin-top: 2px;
  }
  &--not {
    img {
      margin-top: 4px;
      margin-right: 5px;
    }
  }
}
