.home{
    display: flex;
    background: linear-gradient(to right, #5B86E5, #2C4773);
    margin-top: $overHeaderHeight;
    height: 100vh - $overHeaderHeight;
    color: #fff;
    min-height: 500px;
    font-family: $Nunito;

    .max-width{
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    
        @include media-up(md){
            flex-direction: row;
            justify-content: space-around;
        }
    }
}

.home-content{
    width: 400px;
    height: 300px;
    margin: 40px 0;
    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-up(md){
        width: 500px;
        height: 300px;
        display: block;
    }


    //TOP TEXT
    &__top{
        font-size: 23px
    }

    // MID TEXT
    &__mid{
        font-size: 80px;
        letter-spacing: 1.7px;
        font-weight: 600;
        margin-left: -3px;
        color: #EBF0FF;
    }

    // BOTTOM TEXT
    &__bottom{
        font-size: 28px;
        margin: 5px 0;
    }

    &__typing{
        color: var(--secundary-color);
    }

    // BUTTON
    &__button{
        display: inline-block;
        background-color: var(--secundary-color);
        color: #fff;
        font-size: 25px;
        padding: 10px 30px;
        margin-top: 20px;
        border-radius: $borderRadius;
        border: 2px solid var(--secundary-color);
        transition: all 0.3s ease;
    
    
        &:hover{
            color: var(--secundary-color);
            background: none;
        }
    }
}

.home-carousel{
    width: 300px;
    height: 300px;
    @include media-up(md){
        width: 340px;
        height: 340px;
    }
    @include media-up(mgl){
        width: 365px;
        height: 365px;
    }
    @include media-up(xl){
        width: 500px;
        height: 500px;
    }
}



@media (max-width: 1104px){
    .home .max-width{
        margin-left: 0px;
    }
}

@media (max-width: 947px){
    .home-content__bottom{
        font-size: 26px;
    }
}

@media (max-width: 860px){
    .home-content__bottom{
        font-size: 24px;
    }
}

//surface duo
@media (max-width: 690px){
    .home-content__bottom{
        font-size: 22px;
    }
}
//pixel 2
@media (max-width: 500px){
    .home-content__bottom{
        font-size: 23px;
    }
}
//iphone 11 pro
@media (max-width: 400px){
    .home-content__bottom{
        font-size: 20px;
    }
}
//moto g4
@media (max-width: 370px){
    .home-content__bottom{
        font-size: 19px;
    }
}


@media (max-width: 540px) and (max-height: 720px){
    .home{
        height: 115vh - $overHeaderHeight;
    }
}

@media (max-width: 411px) and (max-height: 731px){
    .home{
        height: 115vh - $overHeaderHeight;
    }
}

@media (max-width: 360px) and (max-height: 640px){
    .home{
        height: 130vh - $overHeaderHeight;
    }
}