.profile-container{
    display: grid;
    grid-column-gap: 2rem;
    grid-template-columns: 1fr 3fr 1fr;
    grid-template-rows: auto;
    width: 70%;
    padding: 0;
    margin-top: 2rem;
    margin-bottom: 2rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 1100px){
        grid-template-columns: 1fr 2fr 1fr;
    }
    @media screen and (max-width: 830px){
        grid-template-columns: 1fr;
    }

}