


  .btn-slide {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.1);
    border: 2px solid #f1f1f1;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  
  .btn-slide img {
    width: 25px;
    height: 25px;
    pointer-events: none;
    filter: invert(100%) sepia(1%) saturate(54%) hue-rotate(169deg) brightness(114%) contrast(89%);
  }
  .prev {
    top: 50%;
    left: 20px;
    transform: translateY(-60%);
  }
  .next {
    top: 50%;
    right: 20px;
    transform: translateY(-60%);
  }
  
  
  .container-dots {
    position: absolute;
    bottom: -50%;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
  }
  .dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 3px solid #f1f1f1;
    margin: 0 5px;
    background: #f1f1f1;
    cursor: pointer;
  }
  .dot.active {
    background: rgb(32, 32, 32);
  }
  






.container-text{
    width: 500px;

}

.home .home-content .text-1{
    font-size: 23px;
    color: hsl(221, 59%, 15%);
}
.home .home-content .text-2{
    font-size: 80px;
    letter-spacing: 1.7px;
    font-weight: 600;
    margin-left: -3px;
    color: #EBF0FF;
}
.home .home-content .text-3{
    font-size: 30px;
    margin: 5px 0;
    color: hsl(221, 59%, 15%);
}

.home-banner__typing{
    color: hsl(41, 85%, 61%);
}

.home__img{
    // width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0;
    transition: 1s;
}

.loaded{
    opacity: 1;
}