.ht-margin {
    
    align-items: center;
    // background: linear-gradient(90deg, rgba(56,102,209,1) 25%, rgba(51,94,189,1) 39%, rgba(47,88,176,1) 55%, rgba(46,85,168,1) 74%, rgba(42,82,159,1) 100%);
    background-color: rgba(248, 188, 68, 0.8);
    border-radius: $borderRadius;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
    min-height: 93%;
    width: 100%;

    text-align: left;

    &__img {
        height: 40px;
        margin-bottom: 30px;
        width: 50px !important;
    }

    p {
        color: black ;
        font-size: 130%;
    }

    @include media-up(md){ 
        width: 50%;
    }
}

