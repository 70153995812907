.container {
    width: 100%;
    padding: 15px 20px;
    margin: 0 auto;

    @include media-up(sm){//1024
        padding: 15px 58px;
    }

    @include media-up(md){//1024
        max-width: 1300px;
        padding: 15px 80px;
    }

    @include media-up(lg){//1024
        max-width: 1300px;
        padding: 15px 80px;
    }
}


.special-container {
    width: 100%;
    padding: 15px 0;
    margin: 0 auto;

    @include media-up(lg){//antes eran 992px
        max-width: 1000px;
    }
}

.spaces {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
}

.section{
    // padding-top: 15px;
    // padding-bottom: 15px;
}


.max-width{
    max-width: 100%;
    padding: 0 80px;
    margin: auto;
}

@media (max-width: 991px){
    .max-width{
        padding: 0 50px;
    }
}
@media (max-width: 947px){
    .max-width{
        max-width: 930px;
    }
}
@media (max-width: 690px){
    .max-width{
        padding: 0 23px;
    }
}

