.wt-data-box {
    background-color: lightgray;
    margin: 50px auto;
    height: 460px;
    width: 300px;
    border-radius: $borderRadius;

    p {
        margin: 0px !important;
    }

    &__title {
        padding-top: 7px;
        text-align: center;
        font-weight: bold;
    }

    &__text {
        padding-bottom: 20px;
        text-align: center;
    }
}

.wt-title{
    text-align: center;
    padding-top: 100px;
}

.wt-alert {
    
    min-width: 300px;
    height: 50px;
    width: 40%;
    text-align: center;
    justify-content: center;
    margin: 30px auto;

    &--margin {
        min-width: 300px;
        height: 50px;
        width: 40%;
        text-align: center;
        justify-content: center;
        margin-top: 40px;
        margin-bottom: 5px !important;
    }

    @include media-up(xs) {
        
        margin: 10px auto;

        &--margin {
            margin: 10px auto;
        }
    }
}

.wt-button {
    text-align: center;
    padding-bottom: 50px;
}