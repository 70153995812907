.addresses-container{
    display: grid;
    grid-area: 1 / 2 / auto / span 1;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 1100px){
        grid-area: 1 / 2 / auto / span 1;
    }
    @media screen and (max-width: 830px){
        grid-area: 2 / 1 / auto / 1;
    }
}

.profile-addresses {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    margin-left: auto;
    margin-right: auto;

    &__new-address{
        display: flex;
        align-items: center;
        justify-content: center;

        :hover{
            cursor: pointer;
        }
    }
}

.profile-address-container {
    display: flex;
    margin-bottom: 15px;
    .profile-address-container__radio{
        margin: 10px;
        display: flex;
        align-items: center;
    }
}

.profile-address{
    width: 80%;
    flex-grow: 1;

    .profile-address__title{
        font-size: 1.5rem;
        font-weight: bold;
        color: #000;
        margin: 0;
        padding: 0;
    }

    .profile-address__address{
        font-size: 1rem;
        color: #000;
        margin: 0;
        padding: 0;
    }

    
}

.profile-address-buttons{
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: center;

    .profile-address-buttons__delete{
        margin: 0;
        padding: 0;
        float: right;
        :hover{
            cursor: pointer;
        }
    }

    .profile-address-buttons__edit{
        margin: 0;
        padding: 0;
        float: right;
        :hover{
            cursor: pointer;
        }
    }
}