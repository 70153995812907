.grid-service-container{
    width: 100%;
    height: 100%;
    display: grid;

    hr{
        height: 1px;
        background-color: rgba(0,0,0,0.01);
        margin: 20px;
    }
    span{
        color: #1a1a1a;
        font-family: $Rubik;
    }

    @include media-up(xl){
        grid-template-columns: 6fr 2fr;
    }
}

.grid-step{
    width: 100%;
    background-color: rgba(0,0,0,0.08);
}

.grid-summary{
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    background-color: rgba(0,0,0,0.1);
    padding-bottom: 80px;

    h4 {
        margin: 0px !important;
    }
    @include media-up(sm){
        width: 100%;
        padding-bottom: 0;

    }
}
.test{
    display: flex;
    justify-content: space-between;
}
.grid-service-name-container{
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: auto;
    margin-left: auto;
    margin-top: $spacing-md;
    background-color: #fff;
    padding: 20px;
    border-radius: $borderRadius;

    
    &__img-service{
        height: 60px;
        width: 60px;
        margin-right: 10px;
    }
    &__name-service{
        width: 130px;
        h3{
            font-size: 16px;
            margin-bottom: 0;
        }
        h4{
            font-size: 12px;
            margin-top: 0;
            color:grey;
        }
    }
    @include media-up(sm){
        margin-top: 40px;
        padding: 20px 0;
    } 
}

.grid-service-description{
    width: 80%;
    display: flex;
    flex-direction: column;
    margin-right: auto;
    margin-left: auto;
    margin-top: $spacing-md;
    background-color: #fff;
    padding: 20px;
    border-radius: $borderRadius;
    &__title{
        h3{
            font-size: 16px;
            margin-bottom: 0;
        }
    }
}

.item-details-summary{
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    &--coupon{
        display: flex;
        justify-content: space-evenly;
        // background-color: green;
    }

    &--title {
        font-weight: bold;
    }
}

.appointment-loading {
    display: flex;
    margin-top: 10px;
    h5 {
        margin: 4px 10px !important;
    }

    &__icon {
        border: 3px solid rgb(180, 178, 178); /* Light grey */
        border-top: 3px solid rgb(32, 32, 32); /* Blue */
        border-radius: 50%;
        width: 20px;
        height: 20px;
        animation: spin 1s linear infinite;
    }
      
    @keyframes spin {
        0% { transform: rotate(0deg); }
        100% { transform: rotate(360deg); }
    }
}

.bloqued-view {

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 1;
    background-color: rgba(140, 140, 140, 0.5);

    @include media-up(xl) {
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        z-index: 1;
        background-color: rgba(140, 140, 140, 0.5);
    }
}



.item-empty{
    display: none;
}

.input-coupon{
    width: 80%;
}

.price-container{
    margin: 20px 0;
}

.success-coupon{

}

.danger-coupon{
    
}