.appointment-opt-serv-container {
    justify-content: center;
    text-align: center;
    padding: 20px 20px;
    
    
    &__button {
        margin: 10px 0px;
    }
}
.appointment-opt-serv-size-box {
    display: grid;
    grid-template-columns: repeat(3, 1fr);

    flex: auto auto auto;
    flex-direction: row;
    height: 50%;
    // width: 50%;
    
}
.appointment-opt-serv-size-box-small {
    align-items: center;
    align-content: center;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    
    flex: auto auto auto;
    flex-direction: column;
    height: 100%;
    width: 100%;
    
}


.appointment-opt-serv-price-box {
    box-shadow: 0 0 0 1px var(--secundary-color) inset, 0 0 0 0 transparent !important;
    margin: 5px;
    width: 200px;
    // align-content: center;

    &__color {
        background: rgba(248, 188, 68, 0.6) !important;
        height: 100%;
    }

    @include media-up(md){
        width: 400px;
        height: 190px;
    }
    @media only screen and (max-width: 800px) {
        margin: auto;
        width: 80%;
    }
}

.appointment-opt-serv-text {
    font-size: 90% !important;

    @include media-up(md){
        font-size: 120% !important;
    }
}

.appointment-opt-serv-buttons{
    text-align: center;
    display: flex;
    align-items: center;
}
.appointment-opt-serv-extra{
    vertical-align: middle;
}

.appointment-opt-serv-center {
    text-align: center;
    display: flex;
    align-items: stretch;
}

.appointment-opt-serv-fit {
    width: -moz-fit-content;
    width: fit-content;
    text-align: center;
}

.appointment-opt-serv-inline {
    
    align-items: center;
    align-content: center;
}

.appointment-opt-serv-center-text{
    text-align: center;
}