@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@300;400;500;800&display=swap');

$Rubik: 'Rubik', sans-serif;
$Nunito: 'Nunito', sans-serif;
$hue-color: 199;
$description-size: 14px;

// colors
:root{
    --primary-color: hsl(#{$hue-color}, 74%, 61%);
    --primary-color-hover: hsl(#{$hue-color}, 57%, 53%);
    --primary-color-light: hsl(#{$hue-color}, 92%, 85%);
    --secundary-color: hsl(41, 85%, 61%);
    --secundary-color-hover: hsl(41, 57%, 53%);
    --tertiary-color:      hsl(210, 33%, 9%);
    --primary-color-rgba: rgba(81, 182, 229, 1);

    --light: #fff;
    --dark-color: hsl(199, 28%, 3%);
    --body-color: hsl(#{$hue-color}, 60%, 99%);
    --title-color:hsl(221, 59%, 15%);//hsl(#{$hue-color}, 8%, 15%);
    --text-color:hsl(221, 59%, 20%);//hsl(#{$hue-color}, 8%, 45%);

    --input-color:hsl(#{$hue-color}, 70%, 96%);
    --card-color:hsl(#{$hue-color}, 12%, 96%);
    --active-button: hsl(205,72%,47%);
}

// spacing
$spacing-md: 20px;
$spacing-lg: 40px;

// border radius
$borderRadius: 12px;
$borderRadiusCircle: 50%;

// header height
$headerHeight: 90px;
$overHeaderHeight: 3vh;

$breakpoints:(
    xxs: 360px,
    xs: 400px,
    sm: 480px,
    sml: 510px,
    md: 768px,
    mgl: 810px,
    lg: 1024px,
    xl: 1200px,
    xxl: 1360px,
    xxxl: 1400px,
    4kxl: 3000px,
);

@mixin media-up($breakpoint){
    @media screen and (min-width: map-get($breakpoints, $breakpoint)){
        @content;
    }
}

@mixin breakpoint($point) {
    @if $point == md {
        // 768px
        @media (min-width: 48em) {
            @content;
        }
    }
}

//animation
@mixin animation-mixin($name, $from, $to) {
    @keyframes #{$name} {
      0% {transform: translate3d($from, 0, 0); opacity: 0;}
      100% {transform: translate3d($to, 0, 0); opacity: 1;}
    }
}

@keyframes slide-1 {
    0% {
      transform: translate3d(0, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translate3d(100%, 0, 0);
      opacity: 1;
    }
}

@mixin animation-mixin($name, $color1) {
    @keyframes #{$name} {
      0% {opacity: 0;}
      100% {opacity: 1;}
    }
}

@mixin fade($fade, $timing:"ease", $length:1s, $count:1, $fill:"forward"){ 
    animation: $fade $timing $length;
    animation-iteration-count: $count; 
    animation-fill-mode: $fill; 
}

@keyframes fadeIn { 
    0% { opacity: 0; } 
    100% { opacity: 1; } 
} 
@keyframes fadeOut { 
    0% { opacity: 1; } 
    100% { opacity: 0; } 
}
@keyframes fadeInDown { 
    0% { opacity: 0; transform: translateY(-30px); } 
    100% { opacity: 1; transform: translateY(0);} 
}
@keyframes fadeInUp { 
    0% { opacity: 0; transform: translateY(30px); } 
    100% { opacity: 1; transform: translateY(0);} 
}
@keyframes growUp { 
    0% { transform: scale(1,1); } 
    100% { transform: scale(1.05,1.05);} 
}
@keyframes showLeftToRight {
    from {
        margin-right: 100%;
        width: 300%;
    }

    to {
        margin-right: 0%;
        width: 100%;
    }
}
@keyframes showRightToLeft {
    from {
        margin-left: 100%;
        width: 300%;
    }

    to {
        margin-left: 0%;
        width: 100%;
    }
}
