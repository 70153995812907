.appointment-user-addresses {
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    padding: 0;
    margin: 0;
    margin-left: auto;
    margin-right: auto;
}

.appointment-user-address-container{
    display: flex;
    margin-bottom: 15px;
    .appointment-user-address-container__radio{
        margin: 10px;
        display: flex;
        align-items: center;
    }
}