.mobile-row{
    margin-bottom: $spacing-lg;
    @include media-up(md){
        display: flex;
        width: 900px;
    }
    @include media-up(md){
        display: flex;
        justify-content: space-around;
        width: 900px;
    }
    
}

.mobile-item{
    width: 300px;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    @include media-up(md){
        width: 500px;
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
    &__title{
        font-size: 33px;
        font-family: $Nunito;
        margin-bottom: 30px;
        color: var(--title-color);
        @include media-up(md){
            display: block;
            text-align: left;
        }
    }

    &__description{
        font-size: 16px;
        font-family: $Rubik;
        margin-bottom: 30px;
        color: var(--text-color);
        
        @include media-up(md){
            display: block;
            text-align: left;
        }
    }
    &__img{
        display: flex;
        justify-content: center;
        margin-top: 20px;
    
        @include media-up(md){
            display: block;
            margin-top: 0;
            // width: 900px;
        }
        img{
            height: 300px;
            transition: all 0.45s ease;
            &:hover{
                transform: scale(1.05,1.05);
            }
        }
    }
}

.app-buttons{
    display: flex;
    justify-content: center;
    :last-child{
        margin-left: 18px;
    }
}

.app-button{
    position: relative;
    transition: transform 1s ease-in-out;
    &__img{
        display: block;
        width: 150px;
        height: 100%;
    }

    &__text{
        position: absolute;
        top: 0;
        left: -18px;
        margin-left: 0;
        width: 100%;
        height: 100%;
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
        opacity: 0;
    }

    &:hover &{
        &__text{
            background-color: var(--card-color);
            opacity: 1;
            @include fade(fadeIn, ease, 1s, 1, forward);
        }
        &__img{
            // opacity: 0.05;
            @include fade(fadeOut, ease, 1s, 1, forward);
        }
    }

}