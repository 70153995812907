.read-more-banner{
    width: 100%;
    background-color: hsl(221, 59%, 80%);
    align-items: center;
    justify-content: center;
    margin: 50px 0;
}

.read-more-container{
    padding: 100px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &__img{
        img{
            height: 300px;
        }
        @include media-up(md){
            height: 350px;
        }
        @include media-up(sml){
            // flex-direction: row;
            align-items: flex-start;
        }
        
    }
    img{
        transition: all 0.45s ease;
        &:hover{
            transform: scale(1.05,1.05);
        }
    }
    @include media-up(sml){
        flex-direction: row;
    }
}

.read-more-text-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 200px;

    p{
        color: hsl(221, 59%, 20%);
    }

    @include media-up(sm){
        width: 400px;
    }
    @include media-up(sml){
        // flex-direction: row;
        align-items: flex-start;
    }
    @include media-up(md){
        width: 400px;
    }
    @include media-up(lg){
        width: 500px;
    }
}

.btn-read-more{
    width: 100px;
    background-color: #222;
    border-radius: 4px;
    color: white;
    font-size: 12px;
    font-weight: 500;
    padding: 9px 20px 8px;
    text-align: center;
    &:hover{
        background-color: #333;
    }
}