.appointment-client-form {
    background: linear-gradient(-0deg, #ABCCEE, #ABCCEE, #ABCCEE) !important;
    height: 100vh;
    width: 100vw;
}

.client-form {
    margin-right: auto;
    margin-left: auto;
    text-align: center;
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &__title {
        margin-bottom: 5%;
    }
}

.appointment-client-buttons {
    display: flex;
    justify-content: center;
}