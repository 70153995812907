.menu-container{
    display: flex;
    padding: 0;
    margin: 0;
    border: solid;
    border-radius: 1rem;
    border-width: 1px;
    border-color: gray;
    margin-bottom: 1rem;
}

.profile-menu{
    display: flex;
    flex-direction: column;
    width: 100%;
    &__buttons{
        height: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 1.5rem;

        .ui.tabular.menu .item{
            padding: 0.5rem 1rem;
        }
    }

}

.profile-menu-account{
    height: 40%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 1rem;
    &__avatar{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 75%;
        height: 75%;
        svg{
            width: 100%;
            height: 100%;
        }
    }
    &__name{
        display: flex;
        width: 80%;
        text-align: center;
        justify-content: center;
        font-size: 2rem;
        font-weight: bold;
    }
}