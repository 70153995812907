.appointment-count-container {
    justify-content: center;
    text-align: center;
    padding: 20px 20px;
    
    &__button {
        margin: 10px 0px;
    }
}

.appointment-count-price-box {
    box-shadow: 0 0 0 1px var(--secundary-color) inset, 0 0 0 0 transparent !important;
    margin: auto;
    width: 200px;

    &__color {
        background: rgba(248, 188, 68, 0.8) !important;
    }

    @include media-up(md){
        width: 400px;
    }
}

.appointment-count-text {
    font-size: 90% !important;

    @include media-up(md){
        font-size: 120% !important;
    }
}


