.profile-appointment{
    width: 100%;
    margin-bottom: 1rem;
    display: grid;
    grid-template-columns: 3fr 1fr;

    &__title{
        grid-column: 1 / span 1;
        font-size: 1.5rem;
        font-weight: bold;
        color: #000;
        margin: 0;
        padding: 0;
    }

    &__address{
        grid-column: 1 / span 1;
        font-size: 1rem;
        color: #000;
        margin: 0;
        padding: 0;
    }

    &__status{
        display: grid;
        grid-column: 2 / span 1;
        grid-row: 1 / span 2;
        justify-self: center;
        align-self: center;
        height: 2rem;
        width: auto;

        &--pending{
            background-color: #ffc107;
            color: #000;
            border-radius: 5px;
            padding: 0.5rem;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
        }
        &--finished{
            background-color: #28a745;
            color: #fff;
            border-radius: 5px;
            padding: 0.5rem;
            font-size: 1rem;
            font-weight: bold;
            text-align: center;
        }
    }
}
