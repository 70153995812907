.faq-container{
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    animation: skyblue-to-pink 1s ease;
    h1 {
        font-size: 180%;
        text-decoration: underline;
        text-decoration-color: var(--secundary-color);
    }
    @include media-up(md) {
        h1 {
            font-size: 300%;
        }
    }
    @include fade(fadeInUp, ease, 1s, 1, forward);

}

.faq-subtitle-style {
    margin-top: 60px !important;
    margin-bottom: 60px !important;
    text-align: center;
}

.accordion{
    width: 300px;
    @include media-up(sm){
        width: 400px;
    }
    @include media-up(md){
        width: 500px;
    }
    @include media-up(lg){
        width: 600px;
    }
    @include media-up(lg){
        width: 700px;
    }


}

.item-accordion-container{

    background: var(--card-color);
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 30px 30px 20px 30px;
    cursor: pointer;
    &__question{
        display: flex;
        justify-content: space-between;
        align-items: center;
        h3{
            // color: var(--dark);
            color: rgb(7, 103, 148);
        }
        span{
            font-size: 20px;
            font-weight: 400;
            color: rgb(7, 103, 148);
        }
    }
    &__content{
        max-height: 0;
        overflow: hidden;
        transition: all 0.5s cubic-bezier(0,1,0,1);

        &--show{
            height: auto;
            max-height: 9999px;
            transition: all 0.5s cubic-bezier(1,0,1,0);
        }

        p{
            color: var(--dark);
            font-size: $description-size + 2;
        }
    }
}
