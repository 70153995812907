.overheader{
    background-color: #18263D;
    height: $overHeaderHeight;
    display: flex;
    justify-content: right;
    padding-right: 5%;
    position: fixed;
    z-index: 999;
    width: 100%;
    top: 0;

    &__list{
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &__item{
    }

    &__link{
        color: #fff;
        margin-left: 10px;
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}