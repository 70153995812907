
.containerHeader{
    display: flex;
    align-items: center;
    margin-top: 50px;
    margin-bottom: 60px;

    &__title{
        letter-spacing: -1px;
        font-weight: 600;
        font-size: 25px;
        color: var(--title-color);
        white-space: nowrap;
        margin: 0 15px;
    }
    &__line{
        height: 5px;
        width: 100%;
        background: var(--secundary-color);
        @include fade(showRightToLeft, linear , 1s, 1, forward);
    }
}