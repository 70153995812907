
$spacing-buttons: 5px;

.services-container{
    margin-right: auto;
    margin-left: auto;
    transform: translateY( -$spacing-buttons*7 );
    // width: 600px;
}

.service-button{
    height: 12*$spacing-buttons;
    width: 58*$spacing-buttons;
    background-color: var(--light);
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 15px;
    margin-right: auto;
    margin-left: auto;
    padding-top: 5px;

    @include media-up(xs){
        height: 13*$spacing-buttons;
        width: 77*$spacing-buttons;
        background-color: var(--light);
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5px;  
    }
    @include media-up(sm){
        width: 100*$spacing-buttons;
        height: 16*$spacing-buttons;
        background-color: var(--light);
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5px;
    }
    @include media-up(mdl){
        height: 15*$spacing-buttons;
        width: 65*$spacing-buttons;
        background-color: var(--light);
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5px;
    }
    @include media-up(lg){
        height: 15*$spacing-buttons;
        width: 158*$spacing-buttons;
        background-color: var(--light);
        display: flex;
        flex-direction: row;
        justify-content: center;
        border-radius: 15px;
        margin-right: auto;
        margin-left: auto;
        padding-top: 5px;
    }

    &--active{
        background-color: var(--secundary-color);
        border-radius: 10px;
        @include fade(fadeIn, ease, 1s, 1, forward);
    }

    &__text{
        display: flex;
        justify-content: center;
        width: 11*$spacing-buttons;
        // background-color: lightcoral;
        padding: 15px 0;
        border-radius: 10px;
        text-align: center;
        transform: translateY($spacing-buttons);
        h2{
            font-family: $Nunito;
            font-size: 10px;
        }
        @include media-up(xs){
            display: flex;
            justify-content: center;
            width: 15*$spacing-buttons;
            // background-color: lightcoral;
            padding: 15px 0;
            border-radius: 10px;
            text-align: center;
            transform: translateY($spacing-buttons);
            h2{
                font-family: $Nunito;
                font-size: 12px;
            }
        }
        @include media-up(sm){
            display: flex;
            justify-content: center;
            width: 19*$spacing-buttons;
            // background-color: lightcoral;
            padding: 15px 0;
            border-radius: 10px;
            text-align: center;
            transform: translateY(2*$spacing-buttons);
            h2{
                font-family: $Nunito;
                font-size: 15px;
            }
        }
        @include media-up(mdl){
            display: flex;
            justify-content: center;
            width: 30*$spacing-buttons;
            // background-color: lightcoral;
            padding: 15px 0;
            border-radius: 10px;
            text-align: center;
            transform: translateY(2*$spacing-buttons);
            h2{
                font-family: $Nunito;
                font-size: 18px;
            }
        }
        @include media-up(lg){
            display: flex;
            justify-content: center;
            width: 31*$spacing-buttons;
            // background-color: lightcoral;
            padding: 15px 0;
            border-radius: 10px;
            text-align: center;
            transform: translateY(2*$spacing-buttons);
        }
    }
}