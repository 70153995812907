.pay-container{
    background-color: white;
    padding: 40px 30px;
    width: 100%;
    margin-right: auto;
    margin-left: auto;
    border-radius: $borderRadius;
    margin-top: 5px;
    
    @include media-up(sm){
        margin-top: 90px;
        width: 80%;
    }
}

.pay-method-box{
    // background-color: red;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 10px;
    border-radius: $borderRadius;
    img{
        height: 100%;
    }

}
.pay-method-box:hover{
    background-color: var(--card-color);
}

.pay-image-box{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 180px;
    &--mercadopago{
        // background-color: green;
        img{
            height: 70%;
        }
    }
    &--transfer{
        // background-color: green;
        img{
            height: 120%;
        }
    }
}