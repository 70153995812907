
.footerContainer{
    height: 1000px;
    width: 100%;
    margin-top: 50px;
    background-color: rgba(0,0,0,0.9);

    display: flex;
    align-items: center;

    @include media-up(md){
        height: 600px;
    }
    @include media-up(xl){
        height: 400px;
    }
}

.footerItemsContainer{
    width: 80%;
    margin-left: auto;
    margin-right: auto;

    display: flex;
    flex-direction: column;
    align-items: center;

    @include media-up(md){
        display: grid;
        grid-template-columns: repeat(2, 1fr);
    }
    @include media-up(xl){
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

.footerColumn{
    left: 0;
    margin-bottom: 60px;
    &__title{
        color: aliceblue;
        padding-right: 20px;
        @include media-up(md){
            transform: translateY(20px);
        }
    }
    &__text{
        color: var(--primary-color);
        padding-right: 20px;
        margin-bottom: 12px !important;
        @include media-up(md){
            transform: translateY(20px);
        }
    }
    
    a:hover{
        transition: transform 0.3s ease;
        h5{
            color:  darkgrey;
        }
    }
}

.footerColumnMain{
    
    margin-top: 50px;
    margin-bottom: 60px;
    padding-right: 20px;
    left: 0;
    img{
        height: 60px;
    }
    &__title{
        color: aliceblue;
    }
    &__text{
        color: var(--primary-color);
        margin-bottom: 5px;
    }
    @include media-up(md){
        margin-top: 0;
    }
}

.socialNetworksContainer{
    margin-top: 20px;
    display: flex;
    align-items: center;
    img{
        height: 35px;
        margin-right: 5px;
        margin-left: 5px;
        transition: all 0.1s ease;
        &:hover{
            transform: scale(1.1,1.1);
        }
    }
    &__icon{
        color: var(--light);
    }
}

.sponsorContainer{
    display: flex;
    img{
        height: 50px;
        margin-bottom: 10px;
    }
    @include media-up(md){
        display: flex;
        transform: translateY(20px);
    }
    
}

.iconTextContainer{
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    &__title{
        // color: aliceblue;
        // margin-left: 5px;
    }
    &__text{
        padding: -10px 0px !important;
        margin: 0px 0px !important;
        margin-left: 5px;
        color: var(--primary-color);
    }
    &__icon{
        margin-right: 4px;
    }
    @include media-up(md){
        transform: translateY(20px);
    }
}

.copyrightFooter{
    height: 25px;
    padding-top: 5px;
    padding-bottom: 5px ;
    background-color: var(--dark-color);
    &__copyrightText{
        font-size: 13px;
        color: var(--secundary-color);
        text-align: center;
        margin-left: auto;
        margin-right: auto;
    }
    
}