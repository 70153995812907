.invInput {
    opacity: 0 !important;
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    height: 0 !important;
    width: 0 !important;
    z-index: -1 !important;
}

.bold-limited {
    font-weight: bold;
}

.alert-img {
    width: 100px;
    height: 100px;
    display: block;
    margin: 0px auto 15px auto;
}

.alert-box {
    display: flex;

    p {
        text-align: center;
        margin-top: 30px;
    }
}

.alert-button {
    text-align: center;
    margin-top: 20px;
}

.alert-promo {
    background-color: rgba(96,132,212, 0);
    
    img {
        height: 100%;
        width: 100%;
    }
}