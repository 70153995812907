.appointment-address-container {
    // background-color: red;
    margin: 90px 20px;
    width: 300px;
    margin-right: auto;
    margin-left: auto;
    @include media-up(sm) {
        width: 400px;
    }
    @include media-up(md) {
        width: 500px;
    }
    @include media-up(lg) {
        width: 800px;
    }

    .appointment-address-container__new-address {
        margin: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        :hover {
            cursor: pointer;
        }
    }
}

.address-buttons-container {
    margin-top: 40px;
    display: flex;
    justify-content: center;
}