.choose-row{
    display: block;
    overflow: hidden;
    margin-top: 70px;
    @include media-up(md){
        // display: grid;
        // grid-template-columns: repeat(3, 1fr);
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }
}

.choose-card{
    width: 300px;
    padding: 30px 0 0px;
    background-color: var(--card-color);
    position: relative;
    text-align: center;
    overflow: hidden;
    margin: 0px auto $spacing-lg auto;
    transition: all 0.4s ease;
    &:hover{
        transform: scale(1.05,1.05);
    }
    @include media-up(md){
        width: 250px;
        margin: 0px 50px $spacing-lg 0;
    }

    @include media-up(lg){
        width: 300px;
    }
}
.choose-top{
    width: 130px;
    height: 110px;
    display: inline-block;
    margin-bottom: 50px;
    position: relative;
    z-index: 1;
    &::before{
        content:"";
        width: 100%;
        background: var(--primary-color);
        border-radius: 50%;
        position: absolute;
        bottom: 135%;
        right: 0;
        left: 0;
        transform: scale(3);
        transition: all 0.5s linear 0s;
        height: 100%;
    }

    &--color1::before{
        background: #273FAD;
    };
    &--color2::before{
        background: #4665BF;
    };
    &--color3::before{
        background: #3B78C9;
    };
    &--color4::before{
        background: #507BCD;
    };
    &--color5::before{
        background: #7EA1DB;
    };

    &__circle{
        background-color: var(--card-color);
        height: 120px;
        width: 120px;
        border-radius: 50%;
        transform: scale(1);
        transform: translateY(10px);
        transition: all 0.5s ease 0s;
        margin-left: auto;
        margin-right: auto;
    }

    &__title{
        height: 80px;
        text-align: center;
        p{
            padding-top: 20px;
            font-size: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-family: $Nunito;
            font-weight: bold;
            color: rgba(15,22,30,0.85);
        }
    }
    transition: all 0.45s ease;
}

.choose-bottom{
    padding: 0px 10px;
    margin-bottom: 30px;
    margin-top: -20px;
    height: 100px;
    &__title{
        font-size: 23px;
        margin-bottom: 5px;
    }
    &__description{
        font-size: 13px;
        color: var(--dark-color);
    }
}


.choose-social{
    background-color: var(--primary-color);
    bottom: 0;
    margin-bottom: 0;
    // margin: 100px;
    color: transparent;

    &--color1{
        background: #273FAD;
    };
    &--color2{
        background: #4665BF;
    };
    &--color3{
        background: #3B78C9;
    };
    &--color4{
        background: #507BCD;
    };
    &--color5{
        background: #7EA1DB;
    };
}