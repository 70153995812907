$succes-color: #38E08B;
$failure-color: #ff4747;
.apponiment-pr-title {

    h1 {
        margin-top: 0;
        margin-bottom: 0;
    }

    justify-content: center;
    text-align: center;

    &__color-style {
        color: var(--primary-color);
    }
}
.appointment-pr-banner{
    background-color: $succes-color;
    display: flex;
    justify-content: center;
    align-items: center;
    &--failure{
        background-color:  $failure-color;
    }

}
.banner-pr-banner-container{
    width: 300px;
    height: 200px;
    margin: 20px 0;
    background-color: white;
    border-radius: $borderRadius*3;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img{
        width: 80px;
        margin-top: 20px;
        margin-bottom: 0;
    }
    &__thanks{
        margin: 5px 0 20px 0;
        color: $succes-color;
    }
    &__failure{
        margin: 5px 0 20px 0;
        color: $failure-color;    
    }
    h5{
        margin: 0;
        margin-bottom: 20px;
    }
    @include media-up(sml){
        width: 400px;
        height: 200px;
        img{
            width: 120px;
            margin-top: 20px;
            margin-bottom: 0;
        }
    }
}

.title-app-pr{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 50px 0;
}

.app-pr-content{
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-up(mgl){
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin: 0;
    }
}

.app-pr-content-box{
    background-color: lightgrey;
    border-radius: $borderRadius;
    width: 400px;
    height: 100%;
    min-height: 300px;
    padding: 20px 20px;
    margin: 0;
    margin-left: 10px;
    margin-right: 10px;
    list-style: none;
    &__img{
        height: 20px;
    }
    margin-bottom: 20px;
    @include media-up(mgl){
        margin-bottom: 0;
    }
}

.app-pr-point{
    display: flex;
    justify-content: space-between;
    margin: 10px 0px;
}

.share-app-pr{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px 0;
}

.socialNetwork-resume-container{
    display: flex;
    margin-bottom: 20px;
}

.app-pr-btn-back{
    background-color: var(--secundary-color) !important;
    color: white !important;
    width: 200px;
    height: 80px;
    padding: 20px 20px !important;
    border-radius: $borderRadius !important;
}

.app-pr-back-container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 100px;
}















.apponiment-pr-boxes {

    &__margin-style {
        margin: auto;
    }    
    &__share-info {
        min-height: 250px;
        border-color: var(red);
        border-style: solid;
        border-width: 5px;
        border-radius: 10px;
        margin: 20px 5px;
        
        h1 {
            margin: 10px;
        }

        p {
            margin: 10px;
        }

        a {
            margin: 10px;
        }

        ul {
            list-style: none;
            margin: 10px;
            
            li {
              margin-bottom: 1em;
              padding-left: 1.5em;
              position: relative;
              
              &:after {
                content: '';
                height: .4em;
                width: .4em;
                background: var(--primary-color);
                display: block;
                position: absolute;
                transform: rotate(45deg);
                top: .25em;
                left: 0;
              }
            }
          }

        &--social-style {
            width: 150px;
            height: 200px;
            margin: 10px;
        }

        &--payment-style {
            width: 100%;
            height: 100px;
            margin: 10px 20% 0px 0px;
            max-width: 400px;
            object-fit: contain;
        }

        &--agend-style {
            margin: 10px !important;
            background-color: var(--secundary-color) !important;
        }
    }
    @include media-up(lg) {
        display: flex;
        align-items: center;
        &__share-info {
            width: 800px;
            min-width: 800px;
            border-color: gray;
            border-style: solid;
            border-width: 5px;
            border-radius: 10px;
            margin: 20px 5px;
            display: flex;
        }
    }
}

