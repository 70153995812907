.change-password-container{
    display: grid;
    grid-area: 1 / 2 / auto / span 1;
    width: 60%;
    padding: 0;
    margin: 0;

    @media screen and (max-width: 1100px){
        grid-area: 1 / 2 / auto / span 1;
        width: 100%;
    }
    @media screen and (max-width: 830px) {
        grid-area: 2 / 1 / auto / 1;
        width: 100%;
    }
}