.workflow-row{
    // display: grid;
    // grid-template-columns: repeat(3, 1fr);
    // margin-top: 0;
    display: block;
    // margin-bottom: 20px;

    @include media-up(md){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
}


.workflow-card{
    width: 96%;
    margin: 0 auto $spacing-lg auto; 

    @include media-up(xl){
        margin: 0 auto;
    }
}

.workflow-item{
    transition: all 0.4s ease-out 0s;
    text-align: center;
    position: relative;

    transition: all 0.4s ease;
    &:hover{
        transform: scale(1.05,1.05);
    }
    
    &__img{
        img{
            height: 300px;
            width: 300px;
            object-fit: cover;
    
            @include media-up(md){
                height: 250px;
                width: 250px;
            }
        
            @include media-up(lg){
                height: 300px;
                width: 300px;
            }
        }
    }
}

.workflow-info{
    &__title{
        font-size: 16px;
        margin: 5px 0;
    }
  
    &__text{
        margin-bottom: 20px;
        font-size: 14px;
        width: 310px;
        text-align:center;
        margin: 0 auto;
    }
}




