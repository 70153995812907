.appointment-house-container {
    display: flex;
    justify-content: center;
    text-align: center;
    padding: 40px 20px;
}

.appointment-next-button {
    display: flex;
    justify-content: center;
}

.appointment-price-error {
    display: flex;
    justify-content: center;
    color: red;
    margin-bottom: 5px;
}

.appointment-service-icon {
    margin-left: auto;
    margin-right: auto;
    width: 20px;
    margin-bottom: 30px;
}

.appointment-border {
    border: 1px !important;
    border-color: black !important;
}