.terms-options {
    background: white;
    width: 100%;
    padding: 15px 25%;
    margin: 0 auto;

    h2{
        font-size: 130%;
    }

    @include media-up(md) {
        width: 30%;
        padding: 0px 0%;
        margin: 0;
        h2{
            font-size: 180%;
        }
    }
}

.terms-title-container {
    margin-top: 20px;
    display: flex;
    
    img {
        height: 35px;
        margin-left: auto;
    }
    &__text {
        overflow: hidden;
    }
    &__icon {
        margin-left: 10px;
        color: var(--light);
    }
}

.terms-boxes {
    @include media-up(md) {
        display: flex;
    }

    @include fade(fadeInUp, ease, 1s, 1, forward);

}

.terms-text {
    margin-left: 10px;
    min-height: 800px;
    text-align: justify;
    text-justify: inter-word;
    h1 {
        text-align: start;
        text-justify: inter-word;
        font-size: 200%;
    }

    @include media-up(md) {
        h1 {
            font-size: 300%;
        }
    }
}

.terms-active-box:hover {
    background-color: rgb(223, 223, 223);
    cursor: pointer;
}