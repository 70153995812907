.service-banner{
    padding: 40px 40px;
    background: -webkit-linear-gradient(to right, #5B86E5, #2C4773);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #5B86E5, #2C4773); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    &__title{
        font-family: $Nunito;
        font-size: 28px;
        color: white;
    }

    &__subtitle{
        font-family: $Rubik;
        font-weight: 500;
        font-size: 14px;
        color: black;
    }
}