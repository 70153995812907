.login-container{
    display: flex;
    flex-direction: column;
    width: 90%;
    margin: 0 auto;
    margin-top: 2rem;
    max-width: 500px;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.1);

    &__header{
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 2rem;
        font-weight: bold;
    }

    &__logo{
        display: flex;
        margin-bottom: 20px;
        width: 15vh;
        height: 15vh;
        align-self: center;
        justify-self: center;
    }

    &__forgot-password{
        margin-bottom: 20px;
        text-align: center;
        font-size: 0.8rem;
        color: #888;
    }

    &__send-button{
        display: flex;
        justify-content: center;
        align-items: center;
    }

}
