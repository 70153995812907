.button-calendar {
    text-align: center;
    margin-top: 20px;
}

.loader-top {
    margin: 25% 0%;
}

.app-calendar-text {
    margin-top: 10px;
    text-align: center;
}

.app-calendar-box {
    background-color: white;
    border-style: solid;
    border-color: hsl(205,72%,47%);;
    border-radius: $borderRadius;
    border-width: 2px;
    margin: auto;
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    width: 180px;
    h1 {
        margin-bottom: 5px;
    }
    @include media-up(md){
        margin: 15px;
    }
}

.app-calendar-info {
    display: block;
    margin: auto;
    @include media-up(md){
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        text-align: center;
    }
}

.fc .fc-timegrid-now-indicator-line {
    z-index: 4;
    border-color: red;
    border-width: 3px 0 0;
}