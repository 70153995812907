.navbar{
    position: relative;
    z-index: 999;
    width: 100%;
    top: 0;
    // padding: 5px 0; 
    height: $headerHeight;
    font-family: $Nunito;
    transition: 0.3s all ease;

    display: flex;

    &__active{
        background-color: var(--dark-color);
    }

    &--home{
        top: $overHeaderHeight;
        position: fixed;
    }
}

.navbar .max-width{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.navbar .logo a{
    color: #fff;
    font-size: 35px;
    font-weight: 600;
}

.navbar .menu li{
    list-style: none;
    display: inline-block;
}

.navbar .menu li a{
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    margin-left: 25px;
    transition: color 0.3s ease;
}

.navbar .menu li a:hover{
   color: var(--primary-color);
}




.menu-btn{
    color: #fff;
    font-size: 23px;
    cursor: pointer;
    display: none;
}


@media (max-width: 1050px){
    .menu-btn{
        display: block;
        z-index: 999;
    }
    .navbar .menu{
        position: fixed;
        height: 100vh;
        width: 100%;
        left: -100%;
        top: 0;
        background: #111;
        text-align: center;
        padding-top: 80px;
        transition: 0.5s all ease;
    }

    .navbar .menu--active{
        left: 0;
    }

    .navbar .menu li{
        display: block;
    }

    .navbar .menu li a{
        display: inline-block;
        margin: 20px 0;
        font-size: 25px;
    }
}

.navbar__link{
    width: 200px;
}

.navbar__img{
    width: 100%;
}

.item--active{
    border-bottom: 1px solid var(--secundary-color);
    @include fade(fadeIn, ease, 1s, 1, forward);
}

.header-dropdown{
    display: inline-block;
    
    div{
        margin: 20px 0;
        color: #fff;
        font-size: 18px;
        font-weight: 500;
        margin-left: 25px;
        transition: color 0.3s ease;

        @media screen and (max-width: 1050px){
            display: block;
            margin: 20px 0;
            font-size: 25px;
        }
    }
    
    
}

.ui.dropdown>.menu{
    height: auto;
}