.about-us-square {
    background: -webkit-linear-gradient(to right, #5B86E5, #2C4773);  /* Chrome 10-25, Safari 5.1-6 */
    background: linear-gradient(to right, #5B86E5, #2C4773); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
    position: relative;
    text-align: justify;
    text-justify: inter-word;

    h1{
        color: #BFD8FF;
    }

    &__title-container {
        margin-top: 30px;
        display: flex;
        img {
            height: 35px;
            margin-right: 10px;
            transition: transform .7s ease-in-out;
            &:hover {
                transform: rotate(360deg);
            }
        }
        &--icon {
            margin-left: 10px;
            color: var(--light);
        }
        @include fade(fadeIn, ease, 3s, 1, forward);
    }

    &__text-box {
        width: 100%;
        padding: 15px 0;
        margin: 0 auto;
        font-family: $Rubik;
        font-weight: 500;
        color: var(--dark-color);

        @include media-up(xl) {
            width: 400px;
            padding: 0px 0;
            margin: 0;
        }
        @include fade(fadeInDown, ease, 3s, 1, forward);
    }

    @include media-up(xl) {
        height: 300px;
        background: -webkit-linear-gradient(to right, #5B86E5, #2C4773);  /* Chrome 10-25, Safari 5.1-6 */
        background: linear-gradient(to right, #5B86E5, #2C4773); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
        position: relative;
        clip-path: polygon(0 0, 100% 0, 100% 75%, 0 100%);
    }

    
}

.about-us-square-img {
    position: relative;
    clip-path: polygon(0 0, 100% 0, 100% 70%, 0 90%);

    &__image-size {
        width: 100%;
        height: 250px;
        object-fit: cover;

        @include media-up(xl) {
            width: 100%;
            height: 300px;
        }
    }
    @include media-up(xl) {
        width: 45%;
        position: absolute;
        top: 0;
        margin-top: 90px;
        margin-left: 55%;
        clip-path: polygon(0 0, 100% 0, 100% 75%, 0 90%);
    }

}

.about-us-box-vision {
    margin-top: 100px;
    @include media-up(xl){
        margin-top: 100px;
        margin-right: 220px;
    }

    &__image {
        margin-top: 30px;
        width: 100%;
        max-width: 600px;
        display: block;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    
        @include media-up(md) {
            margin-top: 30px;
            display: block;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
    
        @include media-up(xl){
            display: block;
            object-fit: cover;
            width: 600px;
            height: 350px;
            position: relative;
            right: 1%;
            border-radius: 5%;
        }
        @include fade(fadeInUp, ease, 1s, 1, forward);
    }

    &__text {
        width: 100%;
        max-width: 600px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0 auto;

        &--style {
            text-align: justify;
            text-justify: inter-word;
        }
    
        @include media-up(md) {
            padding-right: 15px;
            padding-left: 15px;
            margin: 0 auto;
        
        
            &--style {
                text-align: justify;
                text-justify: inter-word;
            }
        }
    
        @include media-up(xl) {
            width: 290px;
            height: 350px;
            position: absolute;
            background-color: white;
            top: 0;
            margin-top: 520px;
            margin-left: 55%;
            display: block;
            box-shadow: 2.0px 4px 9.9px 5.0px rgba(0, 0, 0, 0.25);
            border-radius: 5%;


            h1{
                padding-top: 50px;
                padding-left: 30px;
                padding-right: 30px;
                text-align: center;
                @include fade(fadeIn, ease, 1s, 1, forward);
            }

            &--style {
                padding: 10px 30px; 
                text-align: center;
                text-justify: none;
            }
        }
    }
}

.about-us-box-mission {
    margin-top: 100px;
    @include media-up(xl){
        margin-top: 150px;
        margin-right: -12%;
    }

    &__image {
        margin-top: 30px;
        width: 100%;
        max-width: 600px;
        display: block;
        padding-right: 15px;
        padding-left: 15px;
        margin-right: auto;
        margin-left: auto;
    
        @include media-up(md) {
            margin-top: 30px;
            display: block;
            padding-right: 15px;
            padding-left: 15px;
            margin-right: auto;
            margin-left: auto;
        }
    
        @include media-up(xl){
            display: block;
            object-fit: cover;
            width: 600px;
            height: 350px;
            position: relative;
            border-radius: 5%;
        }
        @include fade(fadeInUp, ease, 1s, 1, forward);
    }

    &__text {
        width: 100%;
        max-width: 600px;
        padding-right: 15px;
        padding-left: 15px;
        margin: 0 auto;

        &--style {
            text-align: justify;
            text-justify: inter-word;
        }
    
        @include media-up(lg) {
            padding-right: 15px;
            padding-left: 15px;
            margin: 0 auto;
        
            &--style {
                text-align: justify;
                text-justify: inter-word;
            }
        }

    
        @include media-up(xl) {
            width: 290px;
            height: 350px;
            position: absolute ;
            right: 57%;
            background-color: white;
            top: 0;
            margin-top: 1020px;
            display: block;
            box-shadow: -2.0px 4px 9.9px 5.0px rgba(0, 0, 0, 0.25);
            border-radius: 5%;

            h1{
                padding-top: 50px;
                padding-left: 30px;
                padding-right: 30px;
                text-align: center;
                @include fade(fadeIn, ease, 1s, 1, forward);
            }

            &--style {
                padding: 10px 30px; 
                text-align: center;
                text-justify: none;
            }
        }
    }
}



.about-us-box {
    background: var(--card-color);
    clip-path: polygon(50% 1%, 100% 0, 100% 18%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 1%, 50% 0);
    transform: translateY(50px);
    margin-top: 150px;
    &__information {
        text-align: justify;
        text-justify: inter-word;
        h1 {
            margin-top: 50px;
            color: var(--secundary-color);
        }
        p, li {
            color: var(--dark-color);
        }

        &--ul-margin {
            margin-left: 15px;
            margin-bottom: 100px;
        }

        &--li-margin {
            margin: 15px 0px;
        }
        @include fade(fadeInUp, ease, 1s, 1, forward);
    }

    @include media-up(sm) {
        clip-path: polygon(50% 3%, 100% 0, 100% 18%, 100% 100%, 50% 100%, 0 100%, 0% 70%, 0 2%, 50% 0);
    }

    @include media-up(md) {
        clip-path: polygon(50% 5%, 76% 2%, 100% 0, 100% 70%, 100% 100%, 47% 100%, 0 100%, 0% 70%, 0 6%, 50% 0);
    }
}



