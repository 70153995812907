.onePage-calendar-container {
    text-align: center;
    padding: 90px 20px;
    
    &__button {
        margin: 10px 0px;
    }
}

.onePage-calendar-price-box {
    box-shadow: 0 0 0 1px var(--secundary-color) inset, 0 0 0 0 transparent !important;
    margin: 10px auto;
    width: 200px;
    &__color {
        background: rgba(248, 188, 68, 0.6) !important;
    }

    @include media-up(md){
        width: 400px;
    }
}

.onePage-calendar-text {
    font-size: 90% !important;
    text-align: center;
    margin: 2px;
    padding: 16px;
    @include media-up(md){
        font-size: 120% !important;
    }
}

.onePage-calendar {
    font-size: 90% !important;
    border: solid rgba(196, 145, 44, 0.8);
    border-radius: 20px;
    text-align: center;
    margin: 2px;

    @include media-up(md){
        font-size: 120% !important;
    }
}

// // Triangulo lateral
.react-datepicker__triangle {
    color: rgba(248, 188, 68, 0.8);
}

// Contenedor del form de la fecha/tiempo
.react-datepicker__input-container {
    border-radius: 0px;
}

// Tiempo desplegado
.react-datepicker__time-container {
    width: auto;
}

.react-datepicker__navigation-icon {
    color: black;
}

.react-datepicker__navigation--next {
    color: black;
}

.react-datepicker__navigation, .react-datepicker__navigation--next {
    color: black;
}
// Dias de la semana
.react-datepicker__day-name {
    font-weight: 600;
}


// Dia seleccionado
.react-datepicker__day.react-datepicker__day--keyboard-selected {
border: none;
border-radius: 7px;
background-color: rgba(14, 2, 255, 0.189);
color: var(--white);
}

// Hover dia seleccionado
.react-datepicker__day.react-datepicker__day--keyboard-selected:hover {
border: none;
border-radius: 7px;
background-color: blue;
color: var(--white);
}

// Calendario desplegado
.react-datepicker-popper {
    position: relative;
}

.react-datepicker__navigation {
padding-top: 12px;
color: #000;
}

.react-datepicker {
    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.189);
    border: none !important;
    font-family: "Nunito" !important;
    // position: absolute !important;
}

// Header de mes y dias de semana
.react-datepicker__header {
border-bottom: solid 5px var(--light) !important;
background: rgba(248, 188, 68, 0.8) !important;
}

// Nombre mes arriba
.react-datepicker__current-month {
color: var(--dark) !important;
font-weight: 800 !important;
}

.react-datepicker__day--today {
border-radius: 7px;
border: solid 2px var(--brand) !important;
background-color: green !important;
color: white;
}

.react-datepicker__day--selected {
border: none;
border-radius: 7px;
background-color: blue;
color: white;
}
.react-datepicker__day--excluded{
    border: none;
}
.react-datepicker__time-list-item--disabled {
    display: none;
}


.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover,
.react-datepicker__year-text--selected:hover,
.react-datepicker__year-text--in-selecting-range:hover,
.react-datepicker__year-text--in-range:hover {
    border: none;
    border-radius: 7px !important;
    background-color: var(--brand) !important;
    color: var(--dark) !important;
}