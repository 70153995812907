.header-appointment{
    width: 100%;
    background: var(--card-color);
    display: flex;
    flex-direction: column;
    height: $headerHeight*4.5;
    padding: 0 1.5rem;
    top: 0;
    display: flex;
    align-items: center;
    &__logo{
        height: 60px;
        margin-top: 15px;
        margin-bottom: 10px;
        @include media-up(sm){
            height: 80px;
            margin-right: 100px;
            margin-bottom: 0;
            margin-top: 0;
        }
    }
    @include media-up(sm){
        height: $headerHeight*2;
        flex-direction: row;
    }
    @include media-up(md){
        height: $headerHeight;
        flex-direction: row;
    }
}

.circles-appointment-container{
    width: 100%;
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    justify-content: center;
    align-items: center;
    
    @include media-up(sm){
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-up(md){
        display: flex;
        flex-direction: row;
    }
}

.item-header-appointment{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: $headerHeight/2;
    pointer-events: none;
    
    &__name-step{
        h3{
            margin: 0;
            padding: 0;
            font-size: 12px;
            @include media-up(sm){
                font-size: 14px;
            }
        }
    }

    &--active{
        pointer-events: all;
        cursor: pointer;
    }

    @include media-up(sm){
        height: $headerHeight;
        margin-right: 30px;
        margin-top: 0;
    }
}

.circle-appointment{
    height: 30px;
    width: 30px;
    background-color: rgba(0,0,0,0.1);
    border-radius: $borderRadiusCircle;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px;
    &--active{
        background-color: hsl(205,72%,47%);
        h5{
            margin: 30px 30px;
            color: var(--card-color);
        }
    }
    
    // @include media-up(sm){
    //     height: 30px;
    //     width: 30px;
    //     background-color: rgba(0,0,0,0.1);
    //     border-radius: $borderRadiusCircle;
    //     display: flex;
    //     justify-content: center;
    //     align-items: center;
    //     margin-right: 10px;
    //     &--active{
    //         background-color: hsl(205,72%,47%);
    //         height: 30px;
    //         width: 30px;
    //         h5{
    //             // padding: 30px 30px;
    //             color: var(--card-color);
    //         }
    //     }
     
    // }
}